import React from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';

import Main from '../components/Main';

const FlexUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FlexLi = styled.li`
  display: inline-flex;
  align-self: baseline;

  :before {
    content: '>'; /* Insert content that looks like bullets */
    padding-right: 8px;
  }
`;

const FlexCol = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  // flex-basis: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Home: React.FC<PageProps> = () => (
  <Main>
    <h2>About</h2>
    <p>
      I'm an 4th year undergraduate studying Bachelor Advanced Maths (Honours) /
      Bachelor Computer Science.
    </p>
    <p>My main interests lie in Machine Learning, Security and Statistics.</p>
    <p>Here are some technologies I've been working with recently:</p>
    <FlexUl>
      <FlexCol>
        <FlexLi>React</FlexLi>
        <FlexLi>Typescript</FlexLi>
        <FlexLi>Jenkins</FlexLi>
        <FlexLi>Teamcity</FlexLi>
      </FlexCol>
      <FlexCol>
        <FlexLi>Tensorflow</FlexLi>
        <FlexLi>AWS</FlexLi>
        <FlexLi>GCP</FlexLi>
        <FlexLi>R</FlexLi>
      </FlexCol>
    </FlexUl>
  </Main>
);

export default Home;
